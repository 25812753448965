// Copyright 2024 Sadiant Inc. All Rights Reserved. This software is subject to a license agreement. Unauthorized or unlicensed use is prohibited.
import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { ProfileCoreApiService } from '../../shared/services/core-api/profile-core-api.service';
import { ProfileService } from '../../shared/services/profile.service';

@Injectable()
export class ProfileGuard implements CanActivate {
  constructor(private profileCoreApiService: ProfileCoreApiService, private profileService: ProfileService) {}

  canActivate(): Observable<boolean> {
    return this.profileCoreApiService.getProfile({}).pipe(
      tap(response => this.profileService.setProfile(response)),
      map(() => true)
    );
  }
}
