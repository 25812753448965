// Copyright 2024 Sadiant Inc. All Rights Reserved. This software is subject to a license agreement. Unauthorized or unlicensed use is prohibited.
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TSFixMe } from '@sadiant/models';
import { ToastService } from '@sadiant/toast';
import { Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

@Injectable()
export class ApiErrorHandlerInterceptor implements HttpInterceptor {
  constructor(private toastService: ToastService) {}
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<TSFixMe>> {
    return next.handle(request).pipe(
      tap(success => {
        try {
          if (success instanceof HttpResponse && success.body?.errors) {
            (success.body.errors as string[]).forEach(err => this.toastService.showToastInfoMessage(err));
          }
        } catch (e) {
          console.error(e);
        }
      }),
      catchError(error => {
        if (
          error instanceof HttpErrorResponse &&
          (error.status === 401 || error.url?.includes('account/refresh-token') || error.url?.includes('account/revoke-token'))
        ) {
          return next.handle(request);
        } else {
          try {
            if (error.error instanceof ErrorEvent) {
              this.toastService.showToastErrorMessage(`${error.error.message}`);
            } else {
              if (error?.error?.message) {
                this.toastService.showToastErrorMessage(error?.error?.message);
              } else if (error && error.error && error.error.errors && Object.keys(error.error.errors).length) {
                Object.values(error.error.errors).forEach((error: TSFixMe) => {
                  this.toastService.showToastErrorMessage(error as string);
                });
              } else {
                this.toastService.showToastErrorMessage('Something went wrong');
              }
            }
          } catch (e) {
            console.error(e);
            this.toastService.showToastErrorMessage('Something went wrong');
          }
          return throwError(error);
        }
      })
    );
  }
}
