// Copyright 2024 Sadiant Inc. All Rights Reserved. This software is subject to a license agreement. Unauthorized or unlicensed use is prohibited.
import { Injectable } from '@angular/core';
import { createAction, props, Store } from '@ngrx/store';
import { AppState } from '../../app.state';

export const updateTasks = createAction('[MY_TASK] Update Tasks', props<{ tasksInComplete: number }>());

@Injectable({ providedIn: 'root' })
export class MyTaskActionsService {
  constructor(private store: Store<AppState>) { }

  updateTasks = (tasksInComplete: number): void => this.store.dispatch(updateTasks({ tasksInComplete }));
}
