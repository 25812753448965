<!--Copyright 2024 Sadiant Inc. All Rights Reserved. This software is subject to a license agreement. Unauthorized or unlicensed use is prohibited.-->
<!-- <div style="position: absolute;
            z-index: 9999;
            right: 0;
            bottom: 0;
            background-color: #ffffffbd;">
  USER: <pre>{{(user$ | async)|json}}</pre>
</div> -->
<div class="app-container">
  <router-outlet></router-outlet>
</div>
<app-loading *ngIf="(fetchingData$ | async)?.isFetchingData"></app-loading>
<app-toast></app-toast>
