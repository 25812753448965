// Copyright 2024 Sadiant Inc. All Rights Reserved. This software is subject to a license agreement. Unauthorized or unlicensed use is prohibited.
import { ProfileCoreApiService } from './shared/services/core-api/profile-core-api.service';
import { Injectable } from '@angular/core';
import { CanActivateChild } from '@angular/router';
import { Observable, of } from 'rxjs';
import { map, mergeMap, tap } from 'rxjs/operators';
import { ProfileService } from './shared/services/profile.service';
import { ActivityActionsService, MyTaskActionsService } from './store/actions';
import { AccountCoreApiService } from './shared/services/core-api/account-core-api.service';

@Injectable()
export class DashboardGuard implements CanActivateChild {
  constructor(
    private profileCoreApiService: ProfileCoreApiService,
    private profileService: ProfileService,
    private activityActionService: ActivityActionsService,
    private myTaskActionService: MyTaskActionsService,
    private AccountCoreApiService: AccountCoreApiService
  ) { }

  canActivateChild(): Observable<boolean> {
    if (this.profileService.getProfile()) {
      return of(true);
    } else {
      return this.profileCoreApiService.getProfile({}).pipe(
        tap(response => this.profileService.setProfile(response)),
        mergeMap(() =>
          this.AccountCoreApiService.getGlobalStatus().pipe(
            tap(response => this.activityActionService.updateOffers(response.shiftsOffered, response.rejectedTimesheets)),
            tap(response => this.myTaskActionService.updateTasks(response.tasksInComplete)),
            map(() => true)
          )
        )
      );
    }
  }
}
