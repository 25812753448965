// Copyright 2024 Sadiant Inc. All Rights Reserved. This software is subject to a license agreement. Unauthorized or unlicensed use is prohibited.
import { Injectable } from '@angular/core';
import { createAction, props, Store } from '@ngrx/store';
import { NgxPermissionsService } from 'ngx-permissions';
import { AppState } from '../../app.state';
import { UserRoles } from '../../shared/models/enums/user-roles.enum';

export const updateUserEmail = createAction('[USER] Update Email', props<{ email: string }>());
export const updateUserName = createAction('[USER] Update Name', props<{ firstName: string; lastName: string }>());
export const updateUserRoles = createAction('[USER] Update Roles', props<{ roles: ReadonlyArray<UserRoles> }>());
export const clearUser = createAction('[USER] Clear');

@Injectable({ providedIn: 'root' })
export class UserActionsService {
  constructor(private store: Store<AppState>, private permissionService: NgxPermissionsService) {}

  updateUserEmail = (email: string): void => this.store.dispatch(updateUserEmail({ email }));

  updateUserName = (firstName: string, lastName: string): void => this.store.dispatch(updateUserName({ firstName, lastName }));

  updateUserRoles = (roles: UserRoles[]): void => {
    this.permissionService.loadPermissions(roles);
    this.store.dispatch(updateUserRoles({ roles }));
  };

  clearUser = (): void => {
    this.permissionService.flushPermissions();
    this.store.dispatch(clearUser());
  };
}
