// Copyright 2024 Sadiant Inc. All Rights Reserved. This software is subject to a license agreement. Unauthorized or unlicensed use is prohibited.
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Account } from '@sadiant/data-access';
import { EmptyObject, LoadingBehavior } from '@sadiant/models';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { setLoading } from '../../components/loading/loading.interceptor';
import { EnvConfig } from '../../models/env-config.model';
import { extendGetCurrentDateTimeUtcOut, GetCurrentDateTimeUtcOutExtended } from './helpers/current-date-time-utc.helper';

import ChangePasswordIn = Account.ChangePassword.ChangePasswordIn;
import ChangePasswordOut = Account.ChangePassword.ChangePasswordOut;
import CheckDelegateAccessIn = Account.CheckDelegateAccess.CheckDelegateAccessIn;
import CheckDelegateAccessOut = Account.CheckDelegateAccess.CheckDelegateAccessOut;
import RefreshTokenIn = Account.RefreshToken.RefreshTokenIn;
import RefreshTokenOut = Account.RefreshToken.RefreshTokenOut;
import LoginIn = Account.Login.LoginIn;
import LoginOut = Account.Login.LoginOut;
import RevokeTokenIn = Account.RevokeToken.RevokeTokenIn;
import RevokeTokenOut = Account.RevokeToken.RevokeTokenOut;
import ResetPasswordConfirmationIn = Account.ResetPasswordConfirmation.ResetPasswordConfirmationIn;
import ResetPasswordConfirmationOut = Account.ResetPasswordConfirmation.ResetPasswordConfirmationOut;
import ResetPasswordOut = Account.ResetPassword.ResetPasswordOut;
import RegisterUserIn = Account.RegisterUser.RegisterUserIn;
import RegisterUserOut = Account.RegisterUser.RegisterUserOut;
import BullhornValidateTemporaryCredentialsIn = Account.BullhornValidateTemporaryCredentials.BullhornValidateTemporaryCredentialsIn;
import BullhornValidateTemporaryCredentialsOut = Account.BullhornValidateTemporaryCredentials.BullhornValidateTemporaryCredentialsOut;
import GetCurrentDateTimeUtcIn = Account.GetCurrentDateTimeUtc.GetCurrentDateTimeUtcIn;
import GetCurrentDateTimeUtcOut = Account.GetCurrentDateTimeUtc.GetCurrentDateTimeUtcOut;
import GetGlobalStatusOut = Account.GetGlobalStatus.GetGlobalStatusOut;

@Injectable({ providedIn: 'root' })
export class AccountCoreApiService {
  private readonly url = `${this.envConfig.apiCoreBaseUrl}/account`;

  constructor(private http: HttpClient, private envConfig: EnvConfig) {}

  login(request: LoginIn): Observable<LoginOut> {
    return this.http.post<LoginOut>(`${this.url}/login`, request);
  }
  getRefreshToken(request: RefreshTokenIn): Observable<RefreshTokenOut> {
    return this.http.post<RefreshTokenOut>(`${this.url}/refresh-token`, request);
  }
  logOutRevokeToken(request: RevokeTokenIn): Observable<RevokeTokenOut> {
    return request && request.tokenId > 0 && request.tokenValue ? this.http.post<RevokeTokenOut>(`${this.url}/revoke-token`, request) : of({ errors: [], message: '' });
  }
  validateNewBullhornUser(request: BullhornValidateTemporaryCredentialsIn): Observable<BullhornValidateTemporaryCredentialsOut> {
    return this.http.post<BullhornValidateTemporaryCredentialsOut>(`${this.url}/bullhorn-validate-temp-credentials`, request);
  }
  getCurrentDateTimeUtc(_request: EmptyObject<GetCurrentDateTimeUtcIn>): Observable<GetCurrentDateTimeUtcOutExtended> {
    return this.http.get<GetCurrentDateTimeUtcOut>(`${this.url}/current-date-time-utc`).pipe(map(extendGetCurrentDateTimeUtcOut));
  }
  registerUser(user: RegisterUserIn): Observable<RegisterUserOut> {
    return this.http.post<RegisterUserOut>(`${this.url}/register`, user);
  }
  resetPasswordConfirmation(data: ResetPasswordConfirmationIn): Observable<ResetPasswordConfirmationOut> {
    return this.http.post<ResetPasswordConfirmationOut>(`${this.envConfig.apiCoreBaseUrl}/account/reset-password-confirmation`, data);
  }
  resetPassword(email: string): Observable<ResetPasswordOut> {
    return this.http.post<ResetPasswordOut>(`${this.url}/reset-password`, { email });
  }
  changePassword(changePassword: ChangePasswordIn): Observable<ChangePasswordOut> {
    return this.http.post<ChangePasswordOut>(`${this.url}/change-password`, changePassword);
  }
  checkDelegateAccess(request: CheckDelegateAccessIn): Observable<CheckDelegateAccessOut> {
    return this.http.post<CheckDelegateAccessOut>(`${this.url}/login/check-delegate-access`, request);
  }
  getGlobalStatus(): Observable<GetGlobalStatusOut> {
    return this.http.get<GetGlobalStatusOut>(`${this.url}/globalstatus`);
  }
  getServerDate(loadingBehavior: LoadingBehavior = LoadingBehavior.Normal): Observable<string> {
    return this.http.get(`${this.url}/server-date`, { responseType: 'text', context: setLoading(loadingBehavior) });
  }
}
