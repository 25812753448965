// Copyright 2024 Sadiant Inc. All Rights Reserved. This software is subject to a license agreement. Unauthorized or unlicensed use is prohibited.
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Onboarding } from '@sadiant/data-access';
import { Observable } from 'rxjs';
import { EnvConfig } from '../../models/env-config.model';

import GetApprovedFacilitiesOut = Onboarding.GetApprovedFacilities.GetApprovedFacilitiesOut;
import GetOnboardingIndicatorsOut = Onboarding.GetOnboardingIndicators.GetOnboardingIndicatorsOut;

@Injectable({ providedIn: 'root' })
export class OnboardingCoreApiService {
  constructor(private http: HttpClient, private envConfig: EnvConfig) {}
  private readonly url = `${this.envConfig.apiCoreBaseUrl}/onboarding`;

  getOnboardingIndicators(): Observable<GetOnboardingIndicatorsOut> {
    return this.http.get<GetOnboardingIndicatorsOut>(`${this.url}/indicators`);
  }
  getApprovedFacilities(): Observable<GetApprovedFacilitiesOut> {
    return this.http.get<GetApprovedFacilitiesOut>(`${this.url}/approvedfacilities`);
  }
}
