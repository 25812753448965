// Copyright 2024 Sadiant Inc. All Rights Reserved. This software is subject to a license agreement. Unauthorized or unlicensed use is prohibited.
import { Action, createReducer, on } from '@ngrx/store';
import { activityActions } from '../actions';
import { Store_Activity } from '../actions/activity.model';

const initialState: Store_Activity = {
  offers: 0,
  rejectedTimesheets: 0
} as const;

const _activityReducer = createReducer(
  initialState,
  on(activityActions.updateOffers, (state, { offers, rejectedTimesheets }) => {
    return { ...state, offers, rejectedTimesheets };
  })
);

export function activityReducer(state: Store_Activity | undefined, action: Action): Store_Activity {
  return _activityReducer(state, action);
}
