// Copyright 2024 Sadiant Inc. All Rights Reserved. This software is subject to a license agreement. Unauthorized or unlicensed use is prohibited.
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { enableProdMode, ErrorHandler, importProvidersFrom } from '@angular/core';
import { bootstrapApplication } from '@angular/platform-browser';
import { provideAnimations } from '@angular/platform-browser/animations';
import { provideRouter } from '@angular/router';
import { FullCalendarModule } from '@fullcalendar/angular';
import { ApplicationinsightsAngularpluginErrorService } from '@microsoft/applicationinsights-angularplugin-js';
import { Action, StoreModule } from '@ngrx/store';
import { AppInsightsConfig, AppInsightsService, AppInsightsServiceForLocalDev, GlobalErrorHandler } from '@sadiant/azure-app-insights';
import { provideEnvironmentNgxMask } from 'ngx-mask';
import { NgxPermissionsModule } from 'ngx-permissions';
import { NgxWebstorageModule } from 'ngx-webstorage';
import { MessageService } from 'primeng/api';
import { overrideConfig } from '../../../libs/utils/src';
import { AppComponent } from './app/app.component';
import { appRoutes } from './app/app.routes';
import { AppState } from './app/app.state';
import { ApiErrorHandlerInterceptor } from './app/core/authentication/api-error-handler.interceptor';
import { AuthenticationGuard } from './app/core/authentication/authentication.guard';
import { TokenInterceptor } from './app/core/authentication/token.interceptor';
import { DashboardGuard } from './app/dashboard.guard';
import { ActivityGuard } from './app/modules/activity/activity.guard';
import { OnboaringGuard } from './app/modules/onboarding/onboarding.guard';
import { ProfileGuard } from './app/modules/profile/profile.guard';
import { LoadingInterceptor } from './app/shared/components/loading/loading.interceptor';
import { EnvConfig } from './app/shared/models/env-config.model';
import { activityReducer } from './app/store/reducers/activity.reducer';
import { fetchingDataReducer } from './app/store/reducers/fetching-data.reducer';
import { myTaskReducer } from './app/store/reducers/my-task.reducer';
import { userReducer } from './app/store/reducers/user.reducer';
import { environment } from './environments/environment';

const reducers: Record<keyof AppState, (state: any, action: Action) => any> = {
  user: userReducer,
  fetchingData: fetchingDataReducer,
  offers: activityReducer,
  tasks: myTaskReducer
};

fetch(environment.configUrl, { method: 'get', cache: 'no-cache' })
  .then(response => {
    response
      .json()
      .then((envConfig: EnvConfig) => {
        if (!envConfig.apiCorePathUrl || !envConfig.signalRPathUrl || !envConfig.backendBaseUrl) {
          throw new Error(
            'config.{env}.json not loaded correctly. Check that the file was replaced correctly during azure pipelines deployment.'
          );
        }

        if (envConfig.production) {
          enableProdMode();
        }

        if (!envConfig.isPRODorSTG) {
          envConfig = overrideConfig(envConfig);
        }

        bootstrapApplication(AppComponent, {
          providers: [
            provideAnimations(),
            provideRouter(appRoutes),
            provideEnvironmentNgxMask(),
            /** Order of Interceptors is important */
            { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
            { provide: HTTP_INTERCEPTORS, useClass: LoadingInterceptor, multi: true },
            { provide: HTTP_INTERCEPTORS, useClass: ApiErrorHandlerInterceptor, multi: true },
            /** Guards */
            AuthenticationGuard,
            OnboaringGuard,
            ProfileGuard,
            DashboardGuard,
            ActivityGuard,
            /** Other Modules */
            importProvidersFrom(
              HttpClientModule /** This can be changed in v15 to provideHttpClient() */,
              StoreModule.forRoot(reducers),
              NgxPermissionsModule.forRoot(),
              NgxWebstorageModule.forRoot(),
              FullCalendarModule
            ),
            /** Services */
            MessageService,
            /** DI providers */
            { provide: EnvConfig, useFactory: () => new EnvConfig(envConfig) },
            { provide: AppInsightsConfig, useFactory: () => new AppInsightsConfig(envConfig.appInsightsConnectionString) },
            {
              provide: ErrorHandler,
              useFactory: () => (envConfig.production ? new ApplicationinsightsAngularpluginErrorService() : new GlobalErrorHandler())
            },
            {
              provide: AppInsightsService,
              useFactory: () => (envConfig.production ? new AppInsightsService() : new AppInsightsServiceForLocalDev())
            }
          ]
        }).catch(err => console.error(err));
      })
      .catch(err => console.error(err));
  })
  .catch(err => console.error(err));
