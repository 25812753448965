// Copyright 2024 Sadiant Inc. All Rights Reserved. This software is subject to a license agreement. Unauthorized or unlicensed use is prohibited.
import { Injectable } from '@angular/core';
import { Onboarding } from '@sadiant/data-access';
import { TSFixMe } from '@sadiant/models';
import { format, parse, subHours } from 'date-fns';

import OnboardingIndicator = Onboarding.GetOnboardingIndicators.OnboardingIndicator;

@Injectable({ providedIn: 'root' })
export class OnboardingService {
  private onboardingIndicators: OnboardingIndicator[] | null = null;
  private approvedFacilities: any;
  private messages = {
    Interview: {
      'Not Scheduled': 'We will contact you shortly to schedule an interview.',
      Scheduled: 'You have an interview scheduled on DATE.'
    },
    'Complete Profile': {
      Incomplete: 'Please update your contact information and add a profile photo.'
    },
    References: {
      Incomplete: 'Please provide at least 2 professional references, preferably supervisory or above.',
      'In Review': 'References in review.'
    },
    'Licenses & Certifications': {
      Incomplete: 'Please upload all relevant licensure, certifications, and medical degrees.',
      'In Review': 'Licenses and Certifications in review.'
    },
    Immunizations: {
      Incomplete:
        'Please upload the following immunization records: Current Tdap (Tetanus, Diptheria, Pertussis) vaccine or booster, MMR (Measles, Mumps, Rubella) vaccine, Varicella vaccine, Hep B vaccine, and Tuberculosis test.',
      'In Review': 'Immunizations in review.'
    },
    'Employee Documents': {
      'Not Started': `Here's where you'll sign and upload your employee documents for your facility. Once you are ready for this step, you will receive an email from no_reply@sadianthealth.com that has your username and password.`,
      Incomplete:
        'You should have received an email from no_reply@sadianthealth.com that has your username and password. Please fill out and submit all the required documents.',
      'In Review': 'All documents have been filled out and submitted. They are now under review from our onboarding team.'
    },
    'Background Check & Drug Screen': {
      'Not Started':
        'Once you are ready for this step, an email will come from our third party vendor, Choice Screening, asking for consent to perform a background check.  Once the drug screen is ordered, a second email will come from Choice Screening with instructions and Lab Corp locations that perform the drug screen.  Please note that the lab order for the drug screen will expire 7 days after it is ordered.',
      Ordered:
        'Background check and drug screen have been ordered and must be completed. An email will come from Choice Screening with instructions and Lab Corp locations that perform the drug screen.',
      'In Review': 'Background and drug screen are in review.'
    },
    'Facility Training': {
      'Not Scheduled': 'We will contact you for facility orientation and training.',
      Scheduled: 'You have orientation scheduled on DATE.'
    }
  };

  constructor() {}

  getOnboardingIndicators(): OnboardingIndicator[] | null {
    return this.onboardingIndicators;
  }

  setOnboardingIndicators(onboardingIndicators: OnboardingIndicator[] | null): void {
    this.onboardingIndicators = onboardingIndicators === null ? null : this.processOnboardingIndicators(onboardingIndicators);
  }

  getApprovedFacilities(): any {
    return this.approvedFacilities;
  }

  setApprovedFacilities(approvedFacilities: any): void {
    this.approvedFacilities = approvedFacilities;
  }

  private processOnboardingIndicators(onboardingIndicators: OnboardingIndicator[]): OnboardingIndicator[] {
    onboardingIndicators.forEach(indicator => {
      switch (indicator.Name) {
        case 'Interview':
        case 'Facility Training': {
          if (indicator.Message !== null && indicator.Status === 'Scheduled') {
            let scheduledMessage: string = this.messages[indicator.Name][indicator.Status];
            const formatedDate = parse(indicator.Message, 'M/d/yyyy h:m:s a', new Date());
            const subtractedDate = subHours(formatedDate, 4);
            const finalFormatDate = format(subtractedDate, 'cccc, LLLL d, yyyy p');
            scheduledMessage = scheduledMessage.replace('DATE', finalFormatDate);
            indicator.Message = scheduledMessage;
          } else {
            indicator.Message =
              this.messages[indicator.Name] && (this.messages[indicator.Name] as TSFixMe)[indicator.Status]
                ? (this.messages[indicator.Name] as TSFixMe)[indicator.Status]
                : '';
          }
          break;
        }
        default: {
          indicator.Message =
            (this.messages as TSFixMe)[indicator.Name] && (this.messages as TSFixMe)[indicator.Name][indicator.Status]
              ? (this.messages as TSFixMe)[indicator.Name][indicator.Status]
              : '';
          break;
        }
      }
    });
    return onboardingIndicators;
  }
}
