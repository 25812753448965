// Copyright 2024 Sadiant Inc. All Rights Reserved. This software is subject to a license agreement. Unauthorized or unlicensed use is prohibited.
import { Routes } from '@angular/router';
import { AuthenticationGuard } from './core/authentication/authentication.guard';
import { DashboardGuard } from './dashboard.guard';
import { ActivityGuard } from './modules/activity/activity.guard';
import { OnboaringGuard } from './modules/onboarding/onboarding.guard';
import { ProfileGuard } from './modules/profile/profile.guard';

export const appRoutes: Routes = [
  { path: '', redirectTo: '/Login', pathMatch: 'full' },
  {
    path: '',
    loadComponent: () => import('../app/core/layout/navigation/navigation.component').then(m => m.NavigationComponent),
    children: [
      {
        path: 'Login',
        children: [
          {
            path: '',
            loadComponent: () => import('../app/modules/login/login.component').then(m => m.LoginComponent)
          },
          {
            path: ':error',
            loadComponent: () => import('../app/modules/login/login.component').then(m => m.LoginComponent)
          }
        ]
      },
      {
        path: 'Register',
        loadComponent: () => import('../app/modules/register/register.component').then(m => m.RegisterComponent),
        children: [
          {
            path: 'User-Agreement',
            loadComponent: () => import('../app/modules/register/user-agreement/user-agreement.component').then(m => m.UserAgreementComponent)
          },
          {
            path: 'Terms-Of-Service',
            loadComponent: () => import('../app/modules/register/terms-of-service/terms-of-service.component').then(m => m.TermsOfServiceComponent)
          },
          {
            path: 'Create-Password',
            loadComponent: () => import('../app/modules/register/create-password/create-password.component').then(m => m.CreatePasswordComponent)
          }
        ]
      },
      {
        path: 'Reset-Password',
        children: [
          {
            path: ':email/:code',
            loadComponent: () => import('../app/modules/reset-password/reset-password.component').then(m => m.ResetPasswordComponent)
          },
          {
            path: '**',
            redirectTo: '/Login'
          }
        ]
      },
      {
        path: 'Forgot-Password',
        loadComponent: () => import('../app/modules/forgot-password/forgot-password.component').then(m => m.ForgotPasswordComponent)
      }
    ]
  },
  {
    path: 'Dashboard',
    loadComponent: () => import('../app/core/layout/side-navigation/side-navigation.component').then(m => m.SideNavigationComponent),
    canActivate: [AuthenticationGuard],
    canActivateChild: [DashboardGuard],
    children: [
      {
        path: 'Activity',
        canActivate: [ActivityGuard],
        children: [
          {
            path: '',
            loadComponent: () => import('../app/modules/activity/activity.component').then(m => m.ActivityComponent)
          },
          {
            path: 'add-shift',
            loadComponent: () => import('../app/modules/activity/add-shift/add-shift.component').then(m => m.AddShiftComponent)
          }
        ]
      },
      {
        path: 'Onboarding',
        canActivate: [OnboaringGuard],
        children: [
          {
            path: '',
            loadComponent: () => import('../app/modules/onboarding/onboarding.component').then(m => m.OnboardingComponent)
          }
        ]
      },
      {
        path: 'Profile',
        canActivate: [ProfileGuard],
        children: [
          {
            path: '',
            loadComponent: () => import('../app/modules/profile/profile.component').then(m => m.ProfileComponent)
          }
        ]
      },
      {
        path: 'Scheduling',
        children: [
          {
            path: '',
            loadComponent: () => import('../app/modules/scheduling/scheduling.component').then(m => m.SchedulingComponent)
          }
        ]
      },
      {
        path: 'Credentials',
        children: [
          {
            path: '',
            loadComponent: () => import('../app/modules/credentials/credentials.component').then(m => m.CredentialsComponent)
          }
        ]
      },
      {
        path: 'Skills-Checklist',
        children: [
          {
            path: '',
            loadComponent: () => import('../app/modules/skills-checklist/skills-checklist.component').then(m => m.SkillsChecklistComponent)
          }
        ]
      },
      {
        path: 'My-Task',
        children: [
          {
            path: '',
            loadComponent: () => import('../app/modules/my-task/my-task.component').then(m => m.MyTaskComponent)
          }
        ]
      },
      {
        path: 'Employee-Notices',
        children: [
          {
            path: '',
            loadComponent: () => import('../app/modules/employee-notices/employee-notices.component').then(m => m.EmployeeNoticesComponent)
          },
          {
            path: 'State-Labor_Laws',
            loadComponent: () => import('../app/modules/employee-notices//state-labor-laws/state-labor-laws.component').then(m => m.StateLaborLawsComponent)
          }
        ]
      }
    ]
  },
  { path: '**', redirectTo: '/Login' }
];
