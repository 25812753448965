// Copyright 2024 Sadiant Inc. All Rights Reserved. This software is subject to a license agreement. Unauthorized or unlicensed use is prohibited.
import { Account } from '@sadiant/data-access';
import { DateTimeLocalUS, UsaTimeZone } from '@sadiant/models';
import { format } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';

import GetCurrentDateTimeUtcOut = Account.GetCurrentDateTimeUtc.GetCurrentDateTimeUtcOut;

export interface GetCurrentDateTimeUtcOutExtended extends GetCurrentDateTimeUtcOut {
  date: Date;
  userCurrentTimeZone: DateTimeLocalUS;
}

export function extendGetCurrentDateTimeUtcOut(response: GetCurrentDateTimeUtcOut): GetCurrentDateTimeUtcOutExtended {
  /**
   * Create a new JavaScript Date object based on the timestamp
   * multiplied by 1000 so that the argument is in milliseconds, not seconds.
   */
  const serverDateUtc = new Date(response.unixEpoch * 1000);

  return {
    ...response,
    date: serverDateUtc,
    userCurrentTimeZone: getUserCurrentTimeZone(serverDateUtc)
  };
}

function getUserCurrentTimeZone(serverDateUtc: Date): DateTimeLocalUS {
  const usaTimeZones: UsaTimeZone[] = ['EST5EDT', 'CST6CDT', 'MST7MDT', 'PST8PDT'];
  const usersDateTime = new Date(); /** User Local DateTime */
  const usersDateTimeyyyyMMdd_HH = format(usersDateTime, `yyyy-MM-dd'T'HH`);

  const dateTimeLocalUSItems = usaTimeZones.map(timeZone => ({ ...utcToDateTimeLocalUS(serverDateUtc, timeZone) }));

  const userCurrentTimeZone: DateTimeLocalUS | undefined = dateTimeLocalUSItems.find(d => {
    const [HH] = d.time.split(':') as [string, string];
    return usersDateTimeyyyyMMdd_HH === `${d.date}T${HH}`;
  });

  if (!userCurrentTimeZone) {
    /** If user local time does not match the time of any USA TimeZones default to Central Time */
    console.warn('User Local DateTime does not match any US TimeZone, defaulting to CST6CDT');
    return utcToDateTimeLocalUS(serverDateUtc, 'CST6CDT');
  }
  return userCurrentTimeZone;
}

function utcToDateTimeLocalUS(serverDateUtc: Date, timeZone: UsaTimeZone): DateTimeLocalUS {
  const date = format(utcToZonedTime(serverDateUtc, timeZone), 'yyyy-MM-dd');
  const time = format(utcToZonedTime(serverDateUtc, timeZone), 'HH:mm');
  return { timeZone, date, time };
}
