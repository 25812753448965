// Copyright 2024 Sadiant Inc. All Rights Reserved. This software is subject to a license agreement. Unauthorized or unlicensed use is prohibited.
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Profiles } from '@sadiant/data-access';
import { EmptyObject } from '@sadiant/models';
import { DocumentFileResult, getDefaultProfileImageResponse, handleFileDownload, nameOf } from '@sadiant/utils';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { EnvConfig } from '../../models/env-config.model';

import GetNotificationSettingsOut = Profiles.GetNotificationSettings.GetNotificationSettingsOut;
import UpdateNotificationSettingsIn = Profiles.UpdateNotificationSettings.UpdateNotificationSettingsIn;
import UpdateNotificationSettingsOut = Profiles.UpdateNotificationSettings.UpdateNotificationSettingsOut;
import GetProfileImageIn = Profiles.GetProfileImage.GetProfileImageIn;
import GetProfileIn = Profiles.GetProfile.GetProfileIn;
import GetProfileOut = Profiles.GetProfile.GetProfileOut;
import EditUserProfileIn = Profiles.EditUserProfile.EditUserProfileIn;
import EditUserProfileOut = Profiles.EditUserProfile.EditUserProfileOut;
import GetClinicianTypeOut = Profiles.GetClinicianType.GetClinicianTypeOut;
interface UpdateProfileImageIn extends Profiles.UpdateProfileImage.UpdateProfileImageIn {
  filename: string;
  fileBlob: Blob;
}
import UpdateProfileImageOut = Profiles.UpdateProfileImage.UpdateProfileImageOut;
import TaskTypeProfileUpdateIn = Profiles.TaskTypeProfileUpdate.TaskTypeProfileUpdateIn;
import TaskTypeProfileUpdateOut = Profiles.TaskTypeProfileUpdate.TaskTypeProfileUpdateOut;

@Injectable({ providedIn: 'root' })
export class ProfileCoreApiService {
  private readonly url = `${this.envConfig.apiCoreBaseUrl}/profiles`;

  constructor(private http: HttpClient, private envConfig: EnvConfig) {}

  getProfile(_request: EmptyObject<GetProfileIn>): Observable<GetProfileOut> {
    return this.http.get<GetProfileOut>(`${this.url}`);
  }

  getImage(request: GetProfileImageIn): Observable<DocumentFileResult> {
    let params = new HttpParams();
    params = params.set(nameOf(request).userId, `${request.userId}`);
    if (request.getPreview) {
      params = params.set(nameOf(request).getPreview, `${request.getPreview}`);
    }
    return this.http
      .get<Blob>(`${this.url}/image`, {
        params,
        responseType: 'blob' as 'json',
        observe: 'response'
      })
      .pipe(
        catchError(_err => of(getDefaultProfileImageResponse())),
        map(handleFileDownload)
      );
  }

  updateImage(request: UpdateProfileImageIn): Observable<UpdateProfileImageOut> {
    const formData = new FormData();
    formData.append('file', request.fileBlob, request.filename);
    let params = new HttpParams();
    params = params.set(nameOf(request).userId, `${request.userId}`);
    return this.http.patch<UpdateProfileImageOut>(`${this.url}/image`, formData, { params });
  }

  getDefaultProfileImage(): Observable<DocumentFileResult> {
    return this.http
      .get<Blob>(`assets/img/defaultuser.jpeg`, {
        responseType: 'blob' as 'json',
        observe: 'response'
      })
      .pipe(map(handleFileDownload));
  }

  updateProfile(profile: EditUserProfileIn): Observable<EditUserProfileOut> {
    return this.http.post<EditUserProfileOut>(`${this.url}/edit`, profile);
  }

  getNotificationSettings(userId?: string): Observable<GetNotificationSettingsOut> {
    let params = new HttpParams();
    params = userId ? params.set(`userId`, `${userId}`) : params;
    return this.http.get<GetNotificationSettingsOut>(`${this.url}/notification-settings`, { params });
  }

  updateNotificationSettings(updatedNotificationSettings: UpdateNotificationSettingsIn): Observable<UpdateNotificationSettingsOut> {
    return this.http.post<UpdateNotificationSettingsOut>(`${this.url}/notification-settings`, updatedNotificationSettings);
  }

  updateProfileTask(taskRequest: TaskTypeProfileUpdateIn): Observable<TaskTypeProfileUpdateOut> {
    return this.http.post<TaskTypeProfileUpdateOut>(`${this.url}/tasktype/profile/update`, taskRequest);
  }

  getclinicianType(): Observable<GetClinicianTypeOut> {
    return this.http.get<GetClinicianTypeOut>(`${this.url}/clinician-type`);
  }
}
