// Copyright 2024 Sadiant Inc. All Rights Reserved. This software is subject to a license agreement. Unauthorized or unlicensed use is prohibited.
export class EnvConfig {
  constructor(ec: EnvConfig) {
    this.production = ec.production;
    this.isPRODorSTG = ec.isPRODorSTG;
    /** BackEnd Urls */
    this.apiCorePathUrl = ec.apiCorePathUrl;
    this.signalRPathUrl = ec.signalRPathUrl;
    this.backendBaseUrl = ec.backendBaseUrl;
    /** FrontEnd Urls */
    this.clientPortalUrl = ec.clientPortalUrl;
    /** Keys */
    this.registrationPassword = ec.registrationPassword;
    this.intercomAppId = ec.intercomAppId;
    this.appInsightsConnectionString = ec.appInsightsConnectionString;
    /** Launch Darkly */
    this.inboxEnabled = ec.inboxEnabled;
    this.tasksAreDisplayed = ec.tasksAreDisplayed;
    this.onboardingIsDisplayed = ec.onboardingIsDisplayed;
  }
  readonly production: boolean;
  readonly isPRODorSTG: boolean;
  /** BackEnd Urls */
  readonly apiCorePathUrl: string;
  readonly signalRPathUrl: string;
  readonly backendBaseUrl: string;
  get apiCoreBaseUrl(): string {
    return `${this.backendBaseUrl}/${this.apiCorePathUrl}`;
  }
  get signalRApieUrl(): string {
    return `${this.backendBaseUrl}/${this.signalRPathUrl}`;
  }
  /** FrontEnd Urls */
  readonly clientPortalUrl: string;
  /** Other */
  readonly registrationPassword: string | null;
  readonly intercomAppId: string;
  readonly appInsightsConnectionString: string;
  /** Launch Darkly */
  readonly inboxEnabled: boolean;
  readonly tasksAreDisplayed: boolean;
  readonly onboardingIsDisplayed: boolean;
}
