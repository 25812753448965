// Copyright 2024 Sadiant Inc. All Rights Reserved. This software is subject to a license agreement. Unauthorized or unlicensed use is prohibited.
import { HttpContext, HttpContextToken, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LoadingBehavior } from '@sadiant/models';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { FetchingDataActionsService } from '../../../store/actions/fetching-data.actions';

export const BYPASS_GLOBAL_LOADING_TOKEN = new HttpContextToken(() => false);
@Injectable()
export class LoadingInterceptor implements HttpInterceptor {
  constructor(private fetchingDataActionsService: FetchingDataActionsService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const isRevokeTokenApiCall = req.url.toLowerCase().includes('account/revoke-token');
    if (isRevokeTokenApiCall || req.context.get(BYPASS_GLOBAL_LOADING_TOKEN)) {
      return next.handle(req);
    } else {
      this.fetchingDataActionsService.startFetchingData();
      return next.handle(req).pipe(finalize(() => this.fetchingDataActionsService.stopFetchingData()));
    }
  }
}

export function setLoading(loadingBehavior: LoadingBehavior) {
  if (loadingBehavior === LoadingBehavior.Bypass) {
    return new HttpContext().set(BYPASS_GLOBAL_LOADING_TOKEN, true);
  } else {
    return new HttpContext().set(BYPASS_GLOBAL_LOADING_TOKEN, false);
  }
}
