// Copyright 2024 Sadiant Inc. All Rights Reserved. This software is subject to a license agreement. Unauthorized or unlicensed use is prohibited.
import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { OnboardingCoreApiService } from '../../shared/services/core-api/onboarding-core-api.service';
import { EnvConfig } from '../../shared/models/env-config.model';

@Injectable()
export class ActivityGuard implements CanActivate {
  constructor(private router: Router, private onboardingCoreApiService: OnboardingCoreApiService, public envConfig: EnvConfig) {}

  canActivate(): Observable<boolean> {
    return this.onboardingCoreApiService.getApprovedFacilities().pipe(
      map(response => {
        if (response.facilities.length === 0) {
          if (this.envConfig.onboardingIsDisplayed) {
            this.router.navigate(['/Dashboard/Onboarding']);
            return false;
          } else {
            this.router.navigate(['/Dashboard/My-Task']);
            return false;
          }
        }
        return true;
      })
    );
  }
}
