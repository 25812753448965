// Copyright 2024 Sadiant Inc. All Rights Reserved. This software is subject to a license agreement. Unauthorized or unlicensed use is prohibited.
import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { OnboardingCoreApiService } from '../../shared/services/core-api/onboarding-core-api.service';
import { OnboardingService } from './onboarding.service';

@Injectable()
export class OnboaringGuard implements CanActivate {
  constructor(private onboardingCoreApiService: OnboardingCoreApiService, private onboardingService: OnboardingService) {}

  canActivate(): Observable<boolean> {
    return this.onboardingCoreApiService.getOnboardingIndicators().pipe(
      tap(response => this.onboardingService.setOnboardingIndicators(response.results)),
      map(() => true)
    );
  }
}
