// Copyright 2024 Sadiant Inc. All Rights Reserved. This software is subject to a license agreement. Unauthorized or unlicensed use is prohibited.
import { Injectable } from '@angular/core';
import {
  Profiles,
  TimesheetNotificationSettings,
  TaskNotificationSettings,
  ChatNotificationSettings,
  JobLeadsNotificationSettings
} from '@sadiant/data-access';

import GetProfileOut = Profiles.GetProfile.GetProfileOut;
import TimesheetNotificationSettingOut = TimesheetNotificationSettings.GetTimesheetNotificationSettings.TimesheetNotificationSettingOut;
import TaskNotificationSettingOut = TaskNotificationSettings.GetTaskNotificationSettings.TaskNotificationSettingOut;
import ChatNotificationSettingOut = ChatNotificationSettings.GetChatNotificationSettings.ChatNotificationSettingOut;
import JobLeadsNotificationSettingOut = JobLeadsNotificationSettings.GetJobLeadsNotificationSettings.JobLeadsNotificationSettingOut;

export interface NotificationSetting {
  Id?: number;
  Profile_Id?: number;
  Profile?: null;
  NotificationEvent: string;
  EmailNotification: boolean;
  SmsNotification: boolean;
  InAppNotification: boolean;
}
@Injectable({ providedIn: 'root' })
export class ProfileService {
  private profile: GetProfileOut | null = null;
  private timesheetNotificationSettings: TimesheetNotificationSettingOut[] | null = null;
  private taskNotificationSettings: TaskNotificationSettingOut[] | null = null;
  private chatNotificationSettings: ChatNotificationSettingOut[] | null = null;
  private notificationSettings: NotificationSetting[] | null = null;
  private jobLeadsNotificationSettings: JobLeadsNotificationSettingOut[] | null = null;

  constructor() {}

  getProfile(): GetProfileOut | null {
    return this.profile;
  }

  setProfile(profile: GetProfileOut | null): void {
    this.profile = profile;
  }

  getNotificationSettings(): NotificationSetting[] | null {
    return this.notificationSettings;
  }

  setNotificationSettings(notificationSettings: NotificationSetting[]): void {
    this.notificationSettings = notificationSettings;
  }

  getTimesheetNotificationSettings(): TimesheetNotificationSettingOut[] | null {
    return this.timesheetNotificationSettings;
  }

  setTimesheetNotificationSettings(timesheetNotificationSettings: TimesheetNotificationSettingOut[]): void {
    this.timesheetNotificationSettings = timesheetNotificationSettings;
  }

  getTaskNotificationSettings(): TaskNotificationSettingOut[] | null {
    return this.taskNotificationSettings;
  }

  setTaskNotificationSettings(taskNotificationSettings: TaskNotificationSettingOut[]): void {
    this.taskNotificationSettings = taskNotificationSettings;
  }

  getChatNotificationSettings(): ChatNotificationSettingOut[] | null {
    return this.chatNotificationSettings;
  }

  setChatNotificationSettings(chatNotificationSettings: ChatNotificationSettingOut[]): void {
    this.chatNotificationSettings = chatNotificationSettings;
  }
  getJobLeadsNotificationSettings(): JobLeadsNotificationSettingOut[] | null {
    return this.jobLeadsNotificationSettings;
  }
  setJobLeadsNotificationSettings(jobLeadsNotificationSettings: JobLeadsNotificationSettingOut[]): void {
    this.jobLeadsNotificationSettings = jobLeadsNotificationSettings;
  }
}
