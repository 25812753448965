// Copyright 2024 Sadiant Inc. All Rights Reserved. This software is subject to a license agreement. Unauthorized or unlicensed use is prohibited.
import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { Observable } from 'rxjs';

import { AuthenticationCoreService } from './authentication-core.service';
import { TokenService } from './token.service';

@Injectable()
export class AuthenticationGuard implements CanActivate {
  constructor(private authenticationCoreService: AuthenticationCoreService, private tokenService: TokenService) {}

  canActivate(): Observable<boolean> | Promise<boolean> | boolean {
    if (this.tokenService.getAccessTokenFromLocalStorage()) {
      return true;
    }

    this.authenticationCoreService.logout();
    return false;
  }
}
