// Copyright 2024 Sadiant Inc. All Rights Reserved. This software is subject to a license agreement. Unauthorized or unlicensed use is prohibited.
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, filter, switchMap, take } from 'rxjs/operators';
import { AuthenticationCoreService } from './authentication-core.service';
import { TokenService } from './token.service';
import { Account, AccessToken } from '@sadiant/data-access';

import RefreshTokenOut = Account.RefreshToken.RefreshTokenOut;

@Injectable({ providedIn: 'root' })
export class TokenInterceptor implements HttpInterceptor {
  private refreshTokenSubject: BehaviorSubject<RefreshTokenOut | 'isRefreshing' | 'error' | null> = new BehaviorSubject<
    RefreshTokenOut | 'isRefreshing' | 'error' | null
  >(null);

  constructor(private tokenService: TokenService, private authenticationCoreService: AuthenticationCoreService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (request.url.includes('googleapis')) {
      return next.handle(request);
    } else {
      request = this.addAuthenticationToken(request, this.tokenService.getAccessTokenFromLocalStorage());

      return next.handle(request).pipe(
        catchError(error => {
          if (error instanceof HttpErrorResponse && error.status === 401) {
            return this.handle401Error(request, next);
          } else {
            return throwError(error);
          }
        })
      );
    }
  }

  private addAuthenticationToken(request: HttpRequest<any>, accessToken: AccessToken | null): HttpRequest<any> {
    let headers = request.headers.set('X-Requested-With', 'XMLHttpRequest');
    if (accessToken && accessToken.jwt) {
      headers = headers.set('Authorization', `Bearer ${accessToken.jwt}`);
    }
    return request.clone({ headers });
  }

  private handle401Error(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (this.refreshTokenSubject.value !== 'isRefreshing') {
      this.refreshTokenSubject.next('isRefreshing');

      return this.authenticationCoreService.getNewRefreshToken().pipe(
        catchError(error => {
          this.refreshTokenSubject.next('error');
          this.authenticationCoreService.logout();
          return throwError(error);
        }),
        switchMap(getRefreshTokenResponse => {
          this.refreshTokenSubject.next(getRefreshTokenResponse);
          return next.handle(this.addAuthenticationToken(request, getRefreshTokenResponse.accessToken));
        })
      );
    } else {
      return this.refreshTokenSubject.pipe(
        filter(getRefreshTokenResponse => getRefreshTokenResponse !== 'isRefreshing'),
        take(1),
        switchMap(getRefreshTokenResponse => {
          if (getRefreshTokenResponse === 'error' || getRefreshTokenResponse === null || typeof getRefreshTokenResponse !== 'object') {
            return throwError(getRefreshTokenResponse);
          } else {
            return next.handle(this.addAuthenticationToken(request, getRefreshTokenResponse.accessToken ?? null));
          }
        })
      );
    }
  }
}
