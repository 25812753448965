// Copyright 2024 Sadiant Inc. All Rights Reserved. This software is subject to a license agreement. Unauthorized or unlicensed use is prohibited.
import { Action, createReducer, on } from "@ngrx/store";
import { Store_MY_Tasks } from "../actions/my-task.model";
import { myTaskActions } from "../actions";

const initialState: Store_MY_Tasks = {
  tasksInComplete: 0
} as const;

const _myTaskReducer = createReducer(
  initialState,
  on(myTaskActions.updateTasks, (state, { tasksInComplete }) => {
    return { ...state, tasksInComplete }
  })
)

export function myTaskReducer(state: Store_MY_Tasks | undefined, action: Action): Store_MY_Tasks {
  return _myTaskReducer(state, action);
}

