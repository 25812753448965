// Copyright 2024 Sadiant Inc. All Rights Reserved. This software is subject to a license agreement. Unauthorized or unlicensed use is prohibited.
import { Injectable } from '@angular/core';
import { createAction, props, Store } from '@ngrx/store';
import { AppState } from '../../app.state';

export const updateOffers = createAction('[ACTIVITY] Update Offers', props<{ offers: number, rejectedTimesheets: number }>());

@Injectable({ providedIn: 'root' })
export class ActivityActionsService {
  constructor(private store: Store<AppState>) { }

  updateOffers = (offers: number, rejectedTimesheets: number): void => this.store.dispatch(updateOffers({ offers, rejectedTimesheets }));
}
