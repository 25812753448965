// Copyright 2024 Sadiant Inc. All Rights Reserved. This software is subject to a license agreement. Unauthorized or unlicensed use is prohibited.
import { Injectable } from '@angular/core';
import { SessionStorageService } from 'ngx-webstorage';

export interface BullHornUser {
  id: number | null;
  address: string | null;
  email: string | null;
  firstName: string | null;
  lastName: string | null;
  mobile: string | null;
  name: string | null;
  isRegistered: boolean;
  termsOfServiceConsent: boolean;
  userAgreementConsent: boolean;
}
@Injectable({ providedIn: 'root' })
export class RegisterService {
  private registration: Partial<BullHornUser> = {
    isRegistered: false,
    termsOfServiceConsent: false,
    userAgreementConsent: false
  };

  constructor(private storage: SessionStorageService) {
    if (this.storage.retrieve('registration')) {
      this.registration = this.storage.retrieve('registration');
    } else {
      this.storage.store('registration', this.registration);
    }
  }

  updateRegistration(updatedRegistration: Partial<BullHornUser>): void {
    this.registration = { ...this.registration, ...updatedRegistration };
    this.storage.store('registration', this.registration);
  }

  getRegistration(): Partial<BullHornUser> {
    return this.registration;
  }

  resetRegistration(): void {
    const resetRegistration: Partial<BullHornUser> = {
      isRegistered: false,
      termsOfServiceConsent: false,
      userAgreementConsent: false
    };
    this.storage.store('registration', resetRegistration);
  }
}
